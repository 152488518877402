import { getImage, StaticImage } from "gatsby-plugin-image";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import HeroContactFormNew from "./hero-contact-form-new";
import Navigation from "./navigation";

const SeoHero = ({
  title,
  titleSize,
  description,
  type,
  backgroundImage,
  buttons,
  button1,
  button2,
  button3,
  button1Text,
  button2Text,
  button3Text,
  button1Link,
  button2Link,
  button3Link,
  button1Var,
  button2Var,
  button3Var,
  dubai,
  form,
  yellow,
  page,
}) => {
  const pluginImage = getImage(backgroundImage);
  const span = form ? 7 : 12;
  return (
    <div className="position-relative bg-primary">
      <StaticImage
        src="../images/Hero-svg.svg"
        placeholder="blurred"
        quality={100}
        style={{ zIndex: 0 }}
        className="mb-4 position-absolute w-100 h-100"
      />

      <Navigation dubai={dubai} />
      <Container
        style={{ zIndex: 1 }}
        className="py-4 py-lg-9 position-relative py-md-5 "
      >
        <Row
          className={` pb-lg-5 pb-4 ${
            form ? "align-items-end justify-content-between" : ""
          }`}
        >
          <Col lg={span} className={`text-white ${form ? "" : "text-center"} `}>
            <h1
              dangerouslySetInnerHTML={{ __html: title }}
              className={`pt-3 mb-3 text-uppercase ssp-bold hero-title breaking-text ${
                titleSize || ""
              }`}
            />
            <p
              className="mb-lg-0 "
              style={{ fontSize: "140%", color: " #d1d1d1" }}
            >
              {description}
            </p>
            {form && <div className="mt-5">{buttons}</div>}
          </Col>
          {form && (
            <Col lg={4} className="mt-5 text-white d-none d-lg-block mt-lg-0 ">
              <p className="text-secondary text-center fs-2 mb-1">
                Ready to get started?
              </p>{" "}
              <p className="text-white text-center fs-2">Let's get to work.</p>
              <HeroContactFormNew page={page} />
            </Col>
          )}
        </Row>
        {!form && (
          <Row className={`${form ? "" : "justify-content-center"}`}>
            <Col lg={span} className={`${form ? "" : "text-center"}`}>
              {buttons}
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default SeoHero;
